import { SAME_VALUE, BIGGER_ZERO } from '@/utils/validationMessagesUtils'

export default {
  recordDescriptionErrors () {
    const errors = []
    if (!this.$v.record.description.$dirty) return errors
    !this.$v.record.description.required && errors.push('')
    return errors
  },

  recordValueErrors () {
    const errors = []
    if (!this.$v.record.value.$dirty) return errors
    !(this.$v.record.value.required && this.$v.record.value.$model !== 0) && errors.push('')
    !this.$v.record.value.minValue && errors.push(BIGGER_ZERO)
    return errors
  },

  recordAccountIdErrors () {
    const errors = []
    if (!this.$v.record.accountId.$dirty) return errors
    !this.$v.record.accountId.required && errors.push('')
    this.$v.record.accountIdTo.$model === this.$v.record.accountId.$model && errors.push(SAME_VALUE)
    return errors
  },

  recordAccountIdFromErrors () {
    const errors = []
    if (!this.$v.record.accountIdFrom.$dirty) return errors
    !this.$v.record.accountIdFrom.required && errors.push('')
    this.$v.record.accountIdTo.$model === this.$v.record.accountIdFrom.$model && errors.push(SAME_VALUE)
    return errors
  },

  recordAccountIdToErrors () {
    const errors = []
    if (!this.$v.record.accountIdTo.$dirty) return errors
    !this.$v.record.accountIdTo.required && errors.push('')
    this.$v.record.accountIdTo.$model === this.$v.record.accountIdFrom.$model && errors.push(SAME_VALUE)
    return errors
  },

  recordRecordCategoryIdErrors () {
    const errors = []
    if (!this.$v.record.recordCategoryId.$dirty) return errors
    !this.$v.record.recordCategoryId.required && errors.push('')
    return errors
  },

  contactIdErrors () {
    const errors = []
    if (!this.$v.record.contactId.$dirty) return errors
    !this.$v.record.contactId.required && errors.push('')
    return errors
  }
}